
.offer-button {
  position: relative;
  background-color: transparent;
  border-radius: 30px;
  font-size: 20px;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  height: 50px;
  width: 200px;
  background-color: navy;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 20px;
}

.offer-button:hover {
  transition-duration: 0.1s;
  background-color: #228B22;
  color: white;
}

.offer-button:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px navy;
}

.offer-button:active:after {
  box-shadow: 0 0 0 0 navy;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

@media screen and (max-width: 650px) {
  .offer-button {
    height: 40px;
    width: 150px;
    font-size: 18px;
  }
}

.login-button {
  display: block;
  margin: 20px auto;
  padding: 12px 20px;
  background-color: #4c6ead;
  color: #fff;
  border-radius: 50px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  width: 80%;
  max-width: 400px;
  }
  
  .login-button:hover {
  background-color: #228B22;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-2px);
  }
  
  @media (min-width: 600px) {
  .login-button {
  width: 50%;
  font-size: 24px;
  }
  }
  
  @media (max-width: 599px) {
  .login-button {
  width: 90%;
  font-size: 16px;
  }
  }
  
  @media (max-width: 599px) {
  .login-button {
  animation: race-car 1s ease-in-out;
  }
  }
  
  @keyframes race-car {
  0% {
  transform: translateX(-100%);
  }
  100% {
  transform: translateX(0);
  }
  }
  
  @media (min-width: 600px) {
  .login-button {
  animation: futuristic-glow 1s ease-in-out;
  }
  }
  
  @keyframes futuristic-glow {
  0% {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  }
  100% {
  box-shadow: 0px 10px 20px rgba(0, 255, 0, 0.9);
  }
  }


@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "concav2";
  src: url("/assets/fonts/concav2.ttf") format("truetype"),
    url("/assets/fonts/concav2.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Remarcle";
  src: url("/assets/fonts/Remarcle.ttf") format("truetype"),
    url("/assets/fonts/Remarcle.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "concv2";
  src: url("/assets/fonts/concv2.ttf") format("truetype"),
    url("/assets/fonts/concv2.ttf") format("truetype");

  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LEDBOARD";
  src: url("/assets/fonts/LEDBOARD.TTF") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LEDBDREV";
  src: url("/assets/fonts/LEDBDREV.TTF") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "afont";
  src: url("/assets/fonts/afont.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

.bg-pedal {
  background-image: url("/assets/images/carbonPedal.jpg");
}

.bg-surface_blue {
  background-image: url("/assets/images/surface_blue.jpg");
}

.bg-redLeather {
  background-image: url("/assets/images/redLeather.jpg");
}

.bg-carbon1 {
  background-image: url("/assets/images/carbon1.jpg");
}

.bg-carbon2 {
  background-image: url("/assets/images/carbongreen.png");
}
.bg-leather {
  background-image: url("/assets/images/leatherSmall.png");
}

.bg-garage1 {
  background-image: url("/assets/images/racegarage1.jpg");
}

.color-crimson {
  color: crimson;
}

.color-green {
  color: #0c4524;
}

.color-black {
  color: #191919;
}

.color-brand-blue {
  color: #151bdf;
  
}

.color-white {
  color: white;

}

.brand {
  font-family: concv2;
  font-size: 2rem;
  font-weight: 300;
  transform: translateX(-100rem);
  animation: slideIn 0.5s forwards;
}

// FONTS -----------------------

.afont {
  font-family: "afont", sans-serif;
}

.raceFont1 {
  font-family: "concav2", sans-serif;
}

.raceFont2 {
  font-family: "Remarcle", sans-serif;
}

.raceFont3 {
  font-family: "concv2", sans-serif;
}

.ledFont1 {
  font-family: "LEDBOARD", sans-serif;
}

.ledFont2 {
  font-family: "LEDBDREV", sans-serif;
}

// --- END FONTS ---------------------

.page-margin {
  margin-top: 10rem !important;
}

body {
  background-color: #fff;
}

.ng-select-searchable {
  padding: 0.7rem 0.8rem;
  outline: none;
  border: 1px dashed black;
  border-radius: 10px;
}

.ng-select-container {
  border: unset !important;
}

// .long-modal .jw-modal-body {
//   margin-bottom: 240px !important;
// }

.jw-modal {
  z-index: 100000!important;
  height:auto;
  overflow-y: auto;
  position: fixed!important;
}

#vehicle-detail-modal .jw-modal {
  height: unset!important;
}

.primary-btn {
  background: #4c6ead;
  border-radius: 10px;
  border: none;
  padding: 0.75rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #fff;
  margin-right: 20px;
}
.secondary-btn {
  background: transparent;
  border-radius: 10px;
  border: 1px solid #4c6ead;
  padding: 0.7rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #4c6ead;
}
.warning-btn {
  background: hsla(56, 91%, 33%, 0.76);
  border-radius: 10px;
  border: none;
  padding: 0.75rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #fff;
  margin-right: 20px;
}

.disabled-btn {
  background: #635f5f;
  border-radius: 10px;
  border: none;
  padding: 0.75rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #fff;
  margin-right: 20px;
}


.success-btn {
  background: #1fbb00;
  border-radius: 10px;
  border: none;
  padding: 0.75rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #fff;
  margin-right: 20px;
}

#imageGalleryModal {
  .jw-modal-body {
    padding: 4px!important;
    @media only screen and (max-width: 768px) {
      padding: 0px!important;
      background: unset!important;
    }
  }

  .chevron-right {
    background: linear-gradient(90deg,rgb(240 237 237 / 0%) 0%,rgb(0 0 0 / 48%) 100%);
  }

  .chevron-left {
    background: linear-gradient(90deg,rgb(0 0 0 / 48%) 0%, rgb(240 237 237 / 0%) 100%);
  }
}


.randomCards {
  height: 350px;
  .card {
    height: 316px!important;
  }
  .carousel-cell, .carousel-container, .carousel-cells {
    height: 350px!important;
  }

  .card {
    margin: 0px 24px;
  }
}

.loadingBox {
  backdrop-filter: blur(4px);
}

.w-fit-content {
  width: fit-content;
}

.explorer-filter {
  .ng-arrow-wrapper {
    display: none;
  }
}

.vin-input {
  background: #fff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  outline: none;
  border: 1px dashed black;
  color: #000;
  font-size: 16px;
  line-height: 26px;
  width: 100%;
}

#qrCodeModal .jw-modal-body {
  min-width: unset!important;
  padding: 20px 8px!important;
}

.no-min-h-modal .jw-modal-body {
  min-height: unset!important;
}

.readable {
  line-height: 150%;
}

.dropdown:hover .section-dropdown {
  display: block;
  pointer-events: auto;
  transform: translate(0px, 15px);
}

.dropdown:checked ~ .section-dropdown {
  transform: translate(0px, 50px);
}

.dropdown-toggle {
  letter-spacing: 2px;
  color: white;
  font-weight: bold;
}

.dropdown-item-link {
  position: relative;
  letter-spacing: 2px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: bold;
}

.section-dropdown {
  position: absolute;
  top: 4px;
  right: 0px;
  width: fit-content;
  display: none;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
  ul {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    li {
      color: white;
      font-weight: bold;
    }
    li:hover {
      transition: all 200ms linear;
      color: black;
      background-color: white;
    }
  }
}
.priceBanner {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(145, 50, 50);
  font-family: 'Roboto Slab', serif;
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 5px;
  animation: bannerGlimmer 4s infinite;
  text-shadow: 0 0 0 rgba(0, 255, 0, 0);
  -webkit-animation: textGlimmer 4s infinite;
  -moz-animation: textGlimmer 4s infinite;
  -o-animation: textGlimmer 4s infinite;
  animation: textGlimmer 4s infinite;
}

@keyframes bannerGlimmer {
  0%, 100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
  }
}

@keyframes textGlimmer {
  0%, 100% {
    text-shadow: 0 0 5px rgba(0, 255, 0, 0);
  }
  50% {
    text-shadow: 0 0 10px rgba(0, 255, 0, 0.7), 0 0 20px rgba(0, 255, 0, 0.5);
  }
}

.gold-glow {
  color: #000000;
  position: relative;
}

.gold-glow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to bottom, rgba(255, 223, 0, 0) 0%, rgba(240, 245, 226, 0.3) 50%, rgba(255, 223, 0, 0) 100%);
  animation: gold-glow 6s ease-in-out infinite;
}

@keyframes gold-glow {
  0% {
    opacity: 0.2;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.2;
    transform: scale(1);
  }
}
